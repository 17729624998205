// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/index.tsx");
  import.meta.hot.lastModified = "1742928335449.917";
}
// REMIX HMR END

import React from 'react';
import { useLoaderData } from '@remix-run/react';
import { getCollection, getCollections, getResult } from '~/providers/collections/collections';
import { CollectionCard } from '~/components/collections/CollectionCard';
import { TopSellersProductCard } from '~/components/collections/TopSellersProductCard';
export async function loader({
  request
}) {
  const topSellerCollection = await getCollection("top-sellers");
  const topSellersResult = await getResult(request, 'top-sellers-2');
  const collections = await getCollections(request, {
    take: 20
  });
  return {
    collections,
    topSellerCollection,
    topSellersResult
  };
}
export default function Index() {
  _s();
  const {
    collections,
    topSellerCollection,
    topSellersResult
  } = useLoaderData();
  const headerImage = collections[0]?.featuredAsset?.preview;
  const [openTab, setOpenTab] = React.useState(1);
  return <>
      <div style={{
      position: 'relative'
    }}>
        <img src="/BannerImage.png" alt='Image' />

        <div style={{
        position: 'absolute',
        top: 160,
        left: 150,
        textAlign: 'center'
      }}>
          <span style={{
          color: '#1A2634',
          fontSize: 60,
          fontFamily: 'Anybody',
          fontWeight: '700',
          wordWrap: 'break-word'
        }}>Shop beyond boundaries, <br /></span>
          <span style={{
          color: '#1A2634',
          fontSize: 40,
          fontFamily: 'Anybody',
          fontWeight: '400',
          wordWrap: 'break-word'
        }}>because style knows no limits.<br /></span>
          <span style={{
          color: '#1A2634',
          fontSize: 24,
          fontFamily: 'Anybody',
          fontWeight: '400',
          wordWrap: 'break-word'
        }}>Your journey to extraordinary starts here.</span>
        </div>
      </div>
      {/* <div className="relative">
        <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
          {headerImage && (
            <img
              className="absolute inset-0 w-full"
              src={headerImage + '?w=800'}
              alt="header"
            />
          )}
          <div className="absolute inset-0 bg-gradient-to-br from-zinc-400 to-black mix-blend-darken" />
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-0 bg-gray-900 opacity-50"
        />
        <div className="relative max-w-3xl mx-auto py-32 px-6 flex flex-col items-center text-center sm:py-64 lg:px-0">
          <div className="relative bg-zinc-800 bg-opacity-0 rounded-lg p-0">
            <h1 className="text-6xl text-transparent bg-clip-text font-extrabold tracking-normal lg:text-6xl bg-gradient-to-r from-yellow-600 via-red-500 to-blue-600">
            Shop beyond boundaries,
            </h1>
          </div>
           <p className="mt-4 text-2xl text-white">
          because style knows no limits.{' '}
            <a
              href="#"
              className="text-blue-300 hover:text-blue-500"
            >
              Your journey to extraordinary starts here.
            </a>{' '}
            &{' '}
            <a
              href="~/routes/__cart/index"
              className="text-red-300 hover:text-red-500"
            >
              Shop
            </a>
          </p>
          <p className="mt-4 text-gray-300 space-x-1">
            <BookOpenIcon className="w-5 h-5 inline" />
            <span>Read more:</span>
            <a
              className="text-primary-200 hover:text-primary-400"
              href="https://www.vendure.io/blog/2022/05/lightning-fast-headless-commerce-with-vendure-and-remix"
            >
             Products
            </a>
          </p>
        </div>
       </div> */}
      <div className='main'>
        <div className='container'>
          <div className="infobase">
            <ul className='infolist'>
              <li>
                <div className='info-box'>
                  <div className='info-img'>
                    <img src="/icon1.png" alt="" />
                  </div>
                  <div className='info-base'>
                    <h2>Fast & Free Delivery</h2>
                    <p>On all orders</p>
                  </div>
                </div>
              </li>
              <li>
                <div className='info-box'>
                  <div className='info-img'>
                    <img src="/icon2.png" alt="" />
                  </div>
                  <div className='info-base'>
                    <h2>Fast & Friendly Service</h2>
                    <p>Over 15,000 Reviews</p>
                  </div>
                </div>
              </li>
              <li>
                <div className='info-box'>
                  <div className='info-img'>
                    <img src="/icon3.png" alt="" />
                  </div>
                  <div className='info-base'>
                    <h2>Smooth & Simple Returns</h2>
                    <p>90 Day Return Policy</p>
                  </div>
                </div>
              </li>
              <li>
                <div className='info-box'>
                  <div className='info-img'>
                    <img src="/icon4.png" alt="" />
                  </div>
                  <div className='info-base'>
                    <h2>Guaranteed In Stock Items</h2>
                    <p>Website stocks updated daily</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>



      <section aria-labelledby="category-heading" className="pt-24 sm:pt-32 xl:max-w-7xl xl:mx-auto xl:px-8">
        <div className="headingBox">
          <h2 id="category-heading">
            New and noteworthy Products
          </h2>
        </div>

        <div className="mt-4 flow-root">
          <div className="-my-2">
            <div className="box-content py-2 px-2 relative overflow-x-auto xl:overflow-visible">
              <div className="grid justify-items-center grid-cols-2 md:grid-cols-3 gap-y-8 gap-x-8 sm:px-6 lg:px-8 xl:relative xl:px-0 xl:space-x-0 xl:gap-x-8">
                {collections.map(collection => <CollectionCard key={collection.id} collection={collection} />)}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 px-4 sm:hidden">
          <a href="~/routes/__cart/index#" className="block text-sm font-semibold text-primary-600 hover:text-primary-500">
            Browse all categories
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
        <div className='viewmore'>
          <button className='btn btn-blue'>View More</button>
        </div>
      </section>
      <div>
        <div className='main'>
          <div className='container'>
            <div className='homeservice-sec'>
              <div className="headingBox">
                <h2>
                  Home services at your doorsteps
                </h2>
              </div>
              <div className='homeservice-wrap'>
                <div className='hsLeft'>
                  <div className='hsLeftImg'>
                    <img src="/home-service.png" alt="" />
                  </div>
                </div>
                <div className='hsReft'>
                  <ul className='hsList'>
                    <li>
                      <div className='homeservice-box'>
                        <div className='hs-img'>
                          <img src="/icon5.png" alt="" />
                        </div>
                        <div className='hs-txt'>
                          AC & Appliance Repair
                        </div>
                        <div className='hs-lnk'>
                          <img src="/arrow-icon.png" alt="" />
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className='homeservice-box'>
                        <div className='hs-img'>
                          <img src="/icon6.png" alt="" />
                        </div>
                        <div className='hs-txt'>
                          Electrician & Plumber Carpenter
                        </div>
                        <div className='hs-lnk'>
                          <img src="/arrow-icon.png" alt="" />
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className='homeservice-box'>
                        <div className='hs-img'>
                          <img src="/icon7.png" alt="" />
                        </div>
                        <div className='hs-txt'>
                          Cleaning & Pest Control
                        </div>
                        <div className='hs-lnk'>
                          <img src="/arrow-icon.png" alt="" />
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className='homeservice-box'>
                        <div className='hs-img'>
                          <img src="icon8.png" alt="" />
                        </div>
                        <div className='hs-txt'>
                          Painting & Water Proofing
                        </div>
                        <div className='hs-lnk'>
                          <img src="/arrow-icon.png" alt="" />
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className='homeservice-box'>
                        <div className='hs-img'>
                          <img src="icon8.png" alt="" />
                        </div>
                        <div className='hs-txt'>
                          Painting & Water Proofing
                        </div>
                        <div className='hs-lnk'>
                          <img src="/arrow-icon.png" alt="" />
                        </div>
                      </div>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div className='viewmore'>
              <button className='btn btn-blue'>View More</button>
            </div>
          </div>
        </div>
        <div className='main infowrap'>
          <div className='container'>
            <div className='tabsInfo'>
              <div className='leftTabs'>
                <div className='headingTxt'>
                  <h2>Information</h2>
                </div>
                <ul className="" role="tablist">
                  <li className="">
                    <a className={" " + (openTab === 1 ? "" : "")} onClick={e => {
                    e.preventDefault();
                    setOpenTab(1);
                  }} data-toggle="tab" href="#link1" role="tablist">
                      Moving to Finland
                    </a>
                  </li>
                  <li className="">
                    <a className={" " + (openTab === 2 ? "" : "")} onClick={e => {
                    e.preventDefault();
                    setOpenTab(2);
                  }} data-toggle="tab" href="#link2" role="tablist">
                      Settling in Finland
                    </a>
                  </li>
                  <li className="">
                    <a className={" " + (openTab === 3 ? "" : "")} onClick={e => {
                    e.preventDefault();
                    setOpenTab(3);
                  }} data-toggle="tab" href="#link3" role="tablist">
                      Work and Enterprise
                    </a>
                  </li>
                  <li className="">
                    <a className={" " + (openTab === 4 ? "" : "")} onClick={e => {
                    e.preventDefault();
                    setOpenTab(4);
                  }} data-toggle="tab" href="#link3" role="tablist">
                      Finnish and Swedish
                    </a>
                  </li>
                  <li className="">
                    <a className={" " + (openTab === 4 ? "" : "")} onClick={e => {
                    e.preventDefault();
                    setOpenTab(4);
                  }} data-toggle="tab" href="#link3" role="tablist">
                      Housing
                    </a>
                  </li>
                  <li className="">
                    <a className={" " + (openTab === 4 ? "" : "")} onClick={e => {
                    e.preventDefault();
                    setOpenTab(4);
                  }} data-toggle="tab" href="#link3" role="tablist">
                      Education
                    </a>
                  </li>
                  <li className="">
                    <a className={" " + (openTab === 4 ? "" : "")} onClick={e => {
                    e.preventDefault();
                    setOpenTab(4);
                  }} data-toggle="tab" href="#link3" role="tablist">
                      Health
                    </a>
                  </li>
                  <li className="">
                    <a className={" " + (openTab === 4 ? "" : "")} onClick={e => {
                    e.preventDefault();
                    setOpenTab(4);
                  }} data-toggle="tab" href="#link3" role="tablist">
                      Family
                    </a>
                  </li>
                </ul>
              </div>
              <div className='rightTabs'>
                <div className="relative flex flex-col min-w-0 break-words rounded">
                  <div className="px-4 py-5 flex-auto">
                    <div className="tab-content tab-space">
                      <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                        <div className='content-wrap'>
                          <div className='content-img'>
                            <img src="/finland.png" alt="" />
                          </div>
                          <div className='content-text'>
                            <h2>Moving to Finland</h2>
                            <p>Are you considering moving to Finland? You can move to Finland to work, study, join a family member or start a business.</p>
                          </div>
                        </div>
                      </div>
                      <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                        <p>
                          Completely synergize resource taxing relationships via
                          premier niche markets. Professionally cultivate one-to-one
                          customer service with robust ideas.
                          <br />
                          <br />
                          Dynamically innovate resource-leveling customer service for
                          state of the art customer service.
                        </p>
                      </div>
                      <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                        <p>
                          Efficiently unleash cross-media information without
                          cross-media value. Quickly maximize timely deliverables for
                          real-time schemas.
                          <br />
                          <br /> Dramatically maintain clicks-and-mortar solutions
                          without functional solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* <div className='main'>
          <div className='container'>
            <div className='headingTxt'>
              <h2>Best Sellers</h2>
            </div>
          </div>
         </div> */}
        <section aria-labelledby="category-heading" className="pt-24 sm:pt-32 xl:max-w-7xl xl:mx-auto xl:px-8">
          <div className="headingBox">
            <h2 id="category-heading">
              Best Sellers
            </h2>
          </div>

          <div className="mt-4 flow-root">
            <div className="-my-2">
              <div className="box-content py-2 px-2 relative overflow-x-auto xl:overflow-visible">
                <div className="">
                  <div className="mt-6 grid sm:grid-cols-4 gap-8">
                    {topSellersResult.items.length > 0 ? <>
                        {topSellersResult.items.map(item => <TopSellersProductCard key={item.productId} {...item} />)}
                      </> : <></>}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 px-4 sm:hidden">
            <a href="~/routes/__cart/index#" className="block text-sm font-semibold text-primary-600 hover:text-primary-500">
              Browse all categories
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
          <div className='viewmore'>
            <button className='btn btn-blue'>View More</button>
          </div>
        </section>

        <div className='main'>
          <div className='container'>
            <ul className='serviceList'>
              <li>
                <div className='otherService'>
                  <h2>AC Service Repair</h2>
                  <div className='imgService'>
                    <img src="/img1.png" alt="" />
                  </div>
                </div>
              </li>
              <li>
                <div className='otherService'>
                  <h2>Chimney Repair</h2>
                  <div className='imgService'>
                    <img src="/img2.png" alt="" />
                  </div>
                </div>
              </li>
              <li>
                <div className='otherService'>
                  <h2>Shine your Bathroom</h2>
                  <div className='imgService'>
                    <img src="/img3.png" alt="" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>;
}
_s(Index, "vqjfLQa55VZGn+HKCC8cUGD6yCk=", false, function () {
  return [useLoaderData];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;